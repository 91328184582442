import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    accept: "*/*",
  },
});

axiosClient.interceptors.response.use((res) => res.data);

export default axiosClient;
