"use client"; 
import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
    industry: {},
    type: {},
    category: {},
    tRange: -1,
    priceEndRange: -1,
    brandNameList: [],
    conditionList: [],
    locations: [],
    queryText: "",
    selectedCategory: {},
    selectedType: {},
    capacities: [],
};

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {


        setIndustry(state, param) {
            state.industry = param.payload;
        },
        setCategory(state, param) {
            state.category = param.payload;
        },

        setType(state, param) {
            state.type = param.payload;
        },

        industryRemove(state, param) {
            state.industry = {};
        },

        categoryRemove(state, param) {
            state.category = {};
        },

        typeRemove(state, param) {
            state.type = {};
        },

        setPriceRange(state, param) {
            state.priceStartRange = param.payload.minPrice;
            state.priceEndRange = param.payload.maxPrice;
        },
        removeStartPrice(state, param) {

            state.priceStartRange = -1;
        },

        removeEndPrice(state, param) {

            state.priceEndRange = -1;
        },

        clearFilters(state, para) {

            state.industry = {};
            state.category = {};
            state.type = {};
            state.priceStartRange = -1;
            state.priceEndRange = -1;
            state.brandNameList = [];
            state.conditionList = [];
            state.locations = [];
            state.queryText = "";
            // state.ddIndustry ={};
            state.selectedCategory = {};
            state.selectedType = {};
        },

        handelRemoveSelectedCategory(state, para) {
            // state.ddIndustry ={};

        },

        setItemLocation(state, param) {

            if(param.payload !== undefined && param.payload !== null){
                state.locations = param.payload;
            }
        },

        removeItemLocation(state, param) {

            if (state.locations.length > 0) {

                var index = _.findIndex(state.locations, function (o) { return o === param.payload; });
                if (index === -1) {
                    state.locations.push(param.payload);

                }
                else {
                    _.remove(state.locations, param.payload);
                    state.locations.splice(index, 1);

                }
            }

            else {
                state.locations.push(param.payload);

            }
        },

        setItemCondition(state, param) {

            if(param.payload !== undefined && param.payload !== null){
                state.conditionList = param.payload;
            }
          
        },

        removeItemCondition(state, param) {

            if (state.conditionList.length > 0) {

                var index = _.findIndex(state.conditionList, function (o) { return o === param.payload; });
                if (index === -1) {
                    state.conditionList.push(param.payload);
                }
                else {
                    _.remove(state.conditionList, param.payload);
                    state.conditionList.splice(index, 1);
                }
            }

            else {
                state.conditionList.push(param.payload);

            }
        },

        setItemBrands(state, param) {

            state.brandNameList = param.payload;
        },
        setItemCapacity(state, param) {

            state.capacities = param.payload;
        },

        removeItemBrands(state, param) {
            if (state.brandNameList.length > 0) {
                var index = _.findIndex(state.brandNameList, function (o) { return o === param.payload; });
                if (index === -1) {
                    state.brandNameList.push(param.payload);
                }
                else {
                    _.remove(state.brandNameList, param.payload);
                    state.brandNameList.splice(index, 1);
                }
            }

            else {
                state.brandNameList.push(param.payload);
            }
        },



        setSelectedIndustry(state, param) {
            //   state.ddIndustry = param.payload;
            state.industry = param.industry;
        },

        setSelectedCategory(state, param) {

            state.selectedCategory = param.payload;
        },

        setSelectedType(state, param) {
            state.selectedType = param.payload;
        },

        setQueryText(state, param) {
            state.queryText = param.payload;
        },

        emptyQueryText(state, param) {
            state.queryText = "";
        }




    },
});

//getCategory
export const {
    setCategory,
    setIndustry, industryRemove,
    setType,
    clearFilters, typeRemove,
    categoryRemove, setPriceRange, removeStartPrice, removeEndPrice,
    setItemLocation, setItemCondition,
    setSelectedIndustry, setSelectedCategory, setSelectedType, handelRemoveSelected,
    setQueryText, emptyQueryText,
    setItemBrands, removeItemBrands,removeItemCondition,removeItemLocation,setItemCapacity
} = filterSlice.actions;

export default filterSlice.reducer;