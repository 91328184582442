// utils/auth.js
import axiosClient from './axiosClient';
import * as appConstants from "./constants";

export const login = async (payload) => {
  try {
    const res = await axiosClient.post("Login", payload);
    if (res && res.email !== "") {
      localStorage.setItem(
        "login",
        JSON.stringify({
          login: true,
          token: res.accessToken,
          user: res,
        })
      );
      return res;
    }
  } catch (error) {
    console.error("Login error:", error);
    return null;
  }
};

export const logout = () => {
  localStorage.removeItem("login");
  localStorage.removeItem("cart_items");
  localStorage.removeItem(appConstants.checkoutLocalStorageName.shipping);
  // appConstants.clearCheckoutLocalStorage();
  clearCheckoutLocalStorage();
//   router.push("/Inventory");
};


  function clearCheckoutLocalStorage() {
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.selectedTabSession
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalQuoteSelected
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postQuotesRates
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.postalQuote);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.freightQuote);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.totalShippingCost
    );
    
    localStorage.removeItem(appConstants.checkoutLocalStorageName.ActiveTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.CompletedTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.PPStorage);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.f_ratesRes);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.f_selectedODFLServiceCode
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightPickupDate
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.localPickup);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.shippingTotal);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.f_ODFLOptionValues);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.forceLocalPickup);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carriersLogs);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carrierType);
  
  }
export const getUser = () => {
  if (typeof window !== "undefined") {
    const storedLogin = localStorage.getItem("login");
    if (storedLogin) {
      return JSON.parse(storedLogin);
    }
  }
  return null;
};
