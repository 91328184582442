// live
export const stripePublicKey =
  "pk_live_51KYxllHuUlFANLuSj5XxpNrNmOMmAU2K5RDvLCEEVTLxR8h47WmynDAAdxtbGLaMV0pqnBz6bMnsZYNDn2kbzU5r00RJY5rhJa";

// test mode
// export const stripePublicKey ="pk_test_51KYxllHuUlFANLuSMZKITe7ao32MOOpwkkb54JH5NxbuTedz07nan0UOXgYmD7EyzbX3jQ0j6nAqnp1eYK9ui5Ie00OJjFDfzQ";

export const AppbaseUrl = "https://kingsurplus.com/";
export const baseUrl = "https://kscapi.azurewebsites.net/api/";

export const taxRateAzureFunctionUrl =
  "https://taxratesfunction20241014092411.azurewebsites.net/api/GetTaxRateFunction?city=";
export const LogicAppEmailUrl =
  "https://prod-03.centralus.logic.azure.com:443/workflows/b960253aa74a4d908ed35951cb2c9df9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=08xRwxo5OmTjgnYzXDjCubcJ6qhZ7mgWPXZiQWqucHo";
export const LogicAppEmailUrlOffer =
  "https://prod-78.eastus.logic.azure.com:443/workflows/012648a332dd457781efd277682a691b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YbXeUXxTMzyiToyVskQA8uZVN04ZLbjyWUFF2hpr_5c";
export const SaleLogicEmailUrl =
  "https://prod-26.centralus.logic.azure.com:443/workflows/0a39e6f97c25468aa654537fda72ebe0/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=9CSTgFB08csLVyDNZqzYK7mGF6NiFlHNmR2X_MDk6E0";
export const SendInvoicePDFLoginUrl =
  "https://prod-22.centralus.logic.azure.com:443/workflows/e39a3ac1fdf64b73af20c14a9f9671c5/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=H2clGZmQ2Mk1aT0OlOXUIABBBsxfURa5uifkolrAQo0";
export const emailToAddress = "sales@kingsurplus.com";
export const CAs_KEY_Industry = "industries";
export const CACHE_EXPIRATION_KEY_Industry = "industries_expiration";
export const CACHE_EXPIRATION_DURATION_Industry = 60 * 60 * 1000;
export const CACHE_KEY_Category = "categoryCache";
export const CACHE_EXPIRATION_KEY_Category = "categoryCacheExpiration";
export const CACHE_EXPIRATION_TIME_Category = 1000;
export const config = {
  headers: {
    "QB-Realm-Hostname": "https://kingsurplus-9736.quickbase.com",
    "User-Agent": "{User-Agent}",
    Authorization: "QB-USER-TOKEN b6q57q_pxtb_0_brgxrupd4epjm4brnp34fchrq9q7",
    "Content-Type": "application/json",
  },
};

export const sanitizeSearchTerm = (searchTerm) => {
  if (!isNaN(searchTerm)) {
    return searchTerm;
  }
  return searchTerm
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, "")
    .trim()
    .replace(/\s+/g, "-");
};

export const weightTypeOZ = "ounce";
export const weightTypeLbs = "pound";

export const carrierTypeName = {
  postal: "postal",
  freight: "freight",
  both: "both",
};

export const queryKey = {
  search: "q",
  page: "page",
  sort: "sort",
  locations: "location",
  startPrice: "start_price",
  endPrice: "end_price",
  brands: "brand",
  conditions: "condition",
  capacity: "capacity",
};

// Warning ==> do not change the carriers Id
export const carriersId = {
  ODFL: "b4f53ac9-8fb6-4ed3-87bd-343ee192f879",
  SAIA: "4430a2a5-82dc-4a48-99f3-10f31c1c6057",
  SEFL: "abc2f3ae-05f4-4113-b576-2326ce458502",
  XPO: "76057095-250d-40d2-9d18-e8682be8f735",
  UPS: "se-6552338",
  FedEx: "se-4236084",
  Stamps: "se-6552337",
};

export const carriersName = {
  ODFL: "Old Dominion Freight Line",
  SAIA: "SAIA Motor Freight Lines Inc",
  SEFL: "Southeastern Freight Lines",
  UPS: "United Parcel Service",
  FedEx: "Federal Express",
  Stamps: "Stamps.com",
};
export const ODFlServiceNames = {
  stnd: "Standard",
  gtd_noon: "Guaranteed Noon",
  gtd_pm: "Guaranteed End of Day",
};
export const ODFLserviceOptions = {
  aptd: "Appointment required at delivery",
  cnstd: "Construction site delivery",
  idl: "Inside delivery",
  lftd: "Lift gate required at delivery",
  ltdad: "Limited access delivery",
  res: "Residential delivery",
};
export const EstimatedConditionWeight = {
  maxPostalShipping: 150,
  maxFreightShipping: 4500,
  maxUspsShipping: 70,
};
export const CheckoutPaymentCondition = {
  maxCardAmount: 10000,
};
export const checkoutLocalStorageName = {
  shipping: "shipping",
  selectedTabSession: "selectedTabSession",
  postalQuoteSelected: "postalQuoteSelected",
  postalCarrierName: "postalCarrierName",
  postQuotesRates: "postQuotesRates",
  postalQuote: "postalQuote",
  freightQuote: "freightQuote",
  freightCarrierName: "freightCarrierName",
  shippingTotal: "TotalQuoteAmount",
  PPStorage: "paymentPreferences",
  ActiveTab: "activeStep",
  CompletedTab: "completed",
  freightPickupDate: "freightPickupDate",
  f_selectedODFLServiceCode: "f_selectedODFLServiceCode",
  f_ODFLOptionValues: "f_ODFLOptionValues",
  f_ratesRes: "f_ratesRes",
  localPickup: "localPickupCheckout",
  forceLocalPickup: "forceLocalPickup",
  totalShippingCost: "totalShippingCost",
  carriersLogs: "carriersLogs",
  carrierType: "carrierType",
  cart_items: "cart_items",
};
export const us_states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export function clearCheckoutLocalStorage() {
  localStorage.removeItem(checkoutLocalStorageName.selectedTabSession);
  localStorage.removeItem(checkoutLocalStorageName.postalQuoteSelected);
  localStorage.removeItem(checkoutLocalStorageName.postalCarrierName);
  localStorage.removeItem(checkoutLocalStorageName.postQuotesRates);
  localStorage.removeItem(checkoutLocalStorageName.postalQuote);
  localStorage.removeItem(checkoutLocalStorageName.freightQuote);
  localStorage.removeItem(checkoutLocalStorageName.totalShippingCost);
  localStorage.removeItem(checkoutLocalStorageName.ActiveTab);
  localStorage.removeItem(checkoutLocalStorageName.CompletedTab);
  localStorage.removeItem(checkoutLocalStorageName.PPStorage);
  localStorage.removeItem(checkoutLocalStorageName.f_ratesRes);
  localStorage.removeItem(checkoutLocalStorageName.f_selectedODFLServiceCode);
  localStorage.removeItem(checkoutLocalStorageName.freightCarrierName);
  localStorage.removeItem(checkoutLocalStorageName.freightPickupDate);
  localStorage.removeItem(checkoutLocalStorageName.localPickup);
  localStorage.removeItem(checkoutLocalStorageName.shippingTotal);
  localStorage.removeItem(checkoutLocalStorageName.f_ODFLOptionValues);
  localStorage.removeItem(checkoutLocalStorageName.forceLocalPickup);
  localStorage.removeItem(checkoutLocalStorageName.carriersLogs);
  localStorage.removeItem(checkoutLocalStorageName.carrierType);
}

export function getFormDataHtml(formData) {
  const formDataEntries = Object.entries(formData);
  var template = `<div style="font-family: Segoe UI; max-width: 100%; margin: 0 0; padding: 0px;">`;
  template =
    template +
    `<div style="font-family: Segoe UI;  text-align:center">
  <img  src="https://quickbasedevelopmenbe50.blob.core.windows.net/kingsurplus-assets/logo.png" alt="Logo" style="text-align:center; width: 14vw;">
  <p style="text-align:center;font-size:1rem" >Thank you for your offer! Below are the detailes of it.<p/>
  </div>`;

  template = template + `<ul style="list-style: none; padding: 0;">`;
  for (let [key, value] of formDataEntries.entries()) {
    template =
      template +
      `<li><strong style="text-transform: capitalize" > ${convertCamelCaseToWords(
        value[0]
      )} = </strong> ${value[1]}</li>`;
  }
  template = template + `</ul></div>`;
  return template;
}
export function getDefaultFormDataHtml(formData) {
  const formDataEntries = Object.entries(formData);
  var template = `<div style="font-family: Segoe UI; max-width: 100%; margin: 0 0; padding: 0px;">`;

  template = template + `<ul style="list-style: none; padding: 0;">`;
  for (let [key, value] of formDataEntries.entries()) {
    template =
      template +
      `<li><strong style="text-transform: capitalize" > ${convertCamelCaseToWords(
        value[0]
      )} = </strong> ${value[1]}</li>`;
  }
  template = template + `</ul></div>`;
  return template;
}
const convertCamelCaseToWords = (camelCaseWord) => {
  const words = camelCaseWord.split(/(?=[A-Z])/); // Split the camelCase word based on capital letters
  const convertedWords = words.map((word) => word.toLowerCase());
  return convertedWords.join(" ");
};
export function capitalize(str) {
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
}
export function removeBrackets(label) {
  return label.replace(/\([^)]*\)/, "").trim();
}
export function formattedValue(myValue) {
  if (myValue !== null && myValue !== undefined) {
    let v = Number(myValue);
    if (!isNaN(v)) {
      return v.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return "0.00";
    }
  } else {
    return "0.00";
  }
}

export function formattedValueForPrice(myValue) {
  if (myValue !== null && myValue !== undefined) {
    let v = Number(myValue.toString().replace(/,/g, "")); // remove commas
    if (!isNaN(v)) {
      return v.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return "0.00";
    }
  } else {
    return "0.00";
  }
}

export function parseDecimal(value) {
  return value.replace(/,/g, "");
}
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
