import { configureStore } from '@reduxjs/toolkit';
import cartSlice from './Slices/CartSlice';
import filterSlice from './Slices/FilterSlice';
import productSlice from './Slices/ProductSlice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      cart: cartSlice,
      filter: filterSlice,
      product: productSlice,
    },
  });
};
