"use client"; 
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import setWeightCriteriaForTLT from "../../../Helper/setWeightCriteriaForTLT";
import * as appConstants from "../../../utils/constants";

const initialState = {
  cartItems: typeof window !== 'undefined' && localStorage.getItem("cart_items")
    ? JSON.parse(localStorage.getItem("cart_items"))
    : [],
  cartTotalAmount: 0,
  cartTotalShippingAmount: 0,
  cartPackagingAmount: 0,
  totalItem: 0,
  totalTax:0,
  shippingAddres: {},
  billingAddres: {},
  paymentMethod: {},
  allShippingCost: 0,
  
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    setTotalTax(state, param) {
      let payload = param.payload;
      if (isNaN(payload)) {
        payload = Number(payload);
      }
      state.totalTax = payload;
    },    
    setTotalShippingAmount(state, param) {
      state.cartTotalShippingAmount = param.payload;
    },
    setShippingAddress(state, param) {
      state.shippingAddres = param.payload;
    },
    setBillingAddress(state, param) {
      state.billingAddres = param.payload;
    },
    setPaymentMethod(state, param) {
      state.paymentMethod = param.payload;
    },
    getTotals(state, action) {

      var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (SelectedItems === null) {
        return;
      }
      let { total, tax, packaging, allShippingCost } = SelectedItems.reduce(
        (product, cartItem) => {
          const itemTotal = cartItem.salePrice * cartItem.selectedQty;
          product.total += itemTotal;
          if (
            cartItem.totalShippCost &&
            Number(cartItem.totalShippCost) > 0 &&
            checkShippingEligibility() === false &&
            !product.isLocalPickupOnly
          ) {
            product.allShippingCost += Number(cartItem.totalShippCost);
          }

          let packageCost = getPackagingRate(cartItem.packagingCostData);

          if (setWeightCriteriaForTLT(cartItem)) {
            product.packaging += packageCost * cartItem.selectedQty;
          } else {
            if (!product.isLocalPickupOnly) {
              product.packaging += packageCost;
            }
          }
          return product;
        },
        {
          total: 0,
          packaging: 0,
          allShippingCost: 0,
        }
      );

      var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      var totalCount = 0;
      if (cartItems && cartItems.length > 0) {
        totalCount = cartItems.length;
      }

      state.totalItem = totalCount;
      state.cartTotalAmount = total;
      // state.cartTotalTax = tax;
      state.cartPackagingAmount = (packaging * 100) / 100;
      state.allShippingCost = allShippingCost;

     var totalShpCst =  localStorage.getItem(appConstants.checkoutLocalStorageName.totalShippingCost);

     if(totalShpCst && totalShpCst !== undefined && totalShpCst !== null && totalShpCst !== ""){
      state.cartTotalShippingAmount = totalShpCst;
     }
      
    },

    clearCart(state, action) {
      localStorage.removeItem("cart_items");
    },

    handleAddCart(state, action) {

      debugger
      let selectedItem = action.payload;
      if(!selectedItem.selectedQty && selectedItem.selectedQty == 0 )  {
        selectedItem.selectedQty = 1;
      }
      if(selectedItem.salePrice && Number(selectedItem.salePrice) > 0){
      var itemList = [];
      selectedItem.selectedPackingType = "";
      selectedItem.selectedPackingPrice = 0;
      selectedItem.selectedFreightPrice = 0;
      selectedItem.selectedPostalPrice = 0;
      selectedItem.selectedShippingOption = "";
  
     

      if (setWeightCriteriaForTLT(selectedItem)) {
        selectedItem.selectedShippingOption = "freight";
      } else {
        selectedItem.selectedShippingOption = "postal";
      }
      if (
        selectedItem.estimatedTotalWeight > 4500 ||
        selectedItem.widthInches > 96 ||
        selectedItem.heightInches > 96 ||
        selectedItem.lengthInches > 96 ) {
        selectedItem.selectedShippingType = 1;
      }
      itemList.push(selectedItem);
      var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems === null) {
        localStorage.setItem("cart_items", JSON.stringify(itemList));
      } else {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index === -1) {
          cartItems.push(selectedItem);
          localStorage.removeItem("cart_items");
          localStorage.setItem("cart_items", JSON.stringify(cartItems));
          //  appConstants.clearCheckoutLocalStorage();
          clearCheckoutLocalStorage();
        }
      }
    }
    },

    handleUpdateQtyCart(state, action) {
      var selectedItem = action.payload;
      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems !== null) {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index > -1) {
          if (
            selectedItem.selectedQty > 0 &&
            selectedItem.quantity >= selectedItem.selectedQty
          ) {
            cartItems[index].selectedQty = selectedItem.selectedQty;
            localStorage.setItem("cart_items", JSON.stringify(cartItems));

          }
        }
      }
    },

    handlePostalPrice(state, action) {
      
      var amount = action.payload;
      let calculatedOriginalValue = 0;

      if (amount && amount > 0) {
        calculatedOriginalValue = amount / 1.35; // 200% / 100  = 2
      }

      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];

      if (
        cartItems !== null &&
        calculatedOriginalValue &&
        Number(calculatedOriginalValue) > 0
      ) {
        const countedItems = cartItems.filter(
          (a) => a.selectedShippingOption === "postal"
        ).length;

        for (var index = 0; index < cartItems.length; index++) {
          if (cartItems[index].selectedShippingOption === "postal") {
            cartItems[index].selectedPostalPrice =
              Number(calculatedOriginalValue) / countedItems;
          }
        }

        localStorage.setItem("cart_items", JSON.stringify(cartItems));
      }
    },

    handleFreightPrice(state, action) {
      var amount = action.payload;
      let calculatedOriginalValue = 0;

      if (amount && amount > 0) {
        calculatedOriginalValue = amount / 1.35; // 200% / 100  = 2
      }

      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];

      if (
        cartItems !== null &&
        calculatedOriginalValue &&
        Number(calculatedOriginalValue) > 0
      ) {
        const countedItems = cartItems.filter(
          (a) => a.selectedShippingOption === "freight"
        ).length;
        for (var index = 0; index < cartItems.length; index++) {
          if (cartItems[index].selectedShippingOption === "freight") {
            cartItems[index].selectedFreightPrice =
              Number(calculatedOriginalValue) / countedItems;
          }
        }
        localStorage.setItem("cart_items", JSON.stringify(cartItems));
      }
    },
  },
});

  function clearCheckoutLocalStorage() {
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.selectedTabSession
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalQuoteSelected
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postalCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.postQuotesRates
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.postalQuote);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.freightQuote);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.totalShippingCost
    );
    
    localStorage.removeItem(appConstants.checkoutLocalStorageName.ActiveTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.CompletedTab);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.PPStorage);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.f_ratesRes);
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.f_selectedODFLServiceCode
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightCarrierName
    );
    localStorage.removeItem(
      appConstants.checkoutLocalStorageName.freightPickupDate
    );
    localStorage.removeItem(appConstants.checkoutLocalStorageName.localPickup);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.shippingTotal);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.f_ODFLOptionValues);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.forceLocalPickup);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carriersLogs);
    localStorage.removeItem(appConstants.checkoutLocalStorageName.carrierType);
  
  }
function getPackagingRate(val) {
  let setPrice = 0;
  if (val && checkShippingEligibility() === false) {
    var arr = val.split(";");
    if (arr.length !== undefined && arr[0] !== undefined && arr[0] !== "") {
      let packageSelectVal = arr[0].match(/\d+(\.\d+)?/)[0];

      if (packageSelectVal === "1.1") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.2") {
        setPrice = 15;
      }
      if (packageSelectVal === "1.3") {
        setPrice = 20;
      }
      if (packageSelectVal === "1.4") {
        setPrice = 25;
      }
      if (packageSelectVal === "1.5") {
        setPrice = 30;
      }
      if (packageSelectVal === "1.5.1") {
        setPrice = 35;
      }
      if (packageSelectVal === "1.5.2") {
        setPrice = 40;
      }
      if (packageSelectVal === "1.6") {
        setPrice = 3;
      }
      if (packageSelectVal === "1.7") {
        setPrice = 8;
      }

      if (packageSelectVal === "1.8") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.9") {
        setPrice = 0;
      }
      if (packageSelectVal === "2.1") {
        setPrice = 25;
      }
      if (packageSelectVal === "2.2") {
        setPrice = 35;
      }
      if (packageSelectVal === "3.1") {
        setPrice = 45;
      }
      if (packageSelectVal === "3.2") {
        setPrice = 100;
      }
      if (packageSelectVal === "3.3") {
        setPrice = 0;
      }
      if (packageSelectVal === "4") {
        setPrice = 50;
      }
      if (packageSelectVal === "6") {
        setPrice = 100;
      }
      if (packageSelectVal === "7") {
        setPrice = 200;
      }
      if (packageSelectVal === "8") {
        setPrice = 300;
      }

      if (packageSelectVal === "9.1") {
        setPrice = 400;
      }
      if (packageSelectVal === "9.2") {
        setPrice = 450;
      }

      if (packageSelectVal === "9.3") {
        setPrice = 750;
      }

      if (packageSelectVal === "9.4") {
        setPrice = 0;
      }
      if (packageSelectVal === "9.5") {
        setPrice = 0;
      }

      if (packageSelectVal === "9.6") {
        setPrice = 0;
      }
      if (packageSelectVal === "9.7") {
        setPrice = 0;
      }
      if (packageSelectVal === "9.8") {
        setPrice = 0;
      }
      if (packageSelectVal === "1.1") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.2") {
        setPrice = 15;
      }
      if (packageSelectVal === "1.3") {
        setPrice = 20;
      }
      if (packageSelectVal === "1.4") {
        setPrice = 25;
      }
      if (packageSelectVal === "1.5") {
        setPrice = 30;
      }
      if (packageSelectVal === "1.5.1") {
        setPrice = 35;
      }
      if (packageSelectVal === "1.5.2") {
        setPrice = 40;
      }
      if (packageSelectVal === "1.6") {
        setPrice = 3;
      }
      if (packageSelectVal === "1.7") {
        setPrice = 8;
      }

      if (packageSelectVal === "1.8") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.9") {
        setPrice = 0;
      }
      if (packageSelectVal === "2.1") {
        setPrice = 25;
      }
      if (packageSelectVal === "2.2") {
        setPrice = 35;
      }
      if (packageSelectVal === "3.1") {
        setPrice = 45;
      }
      if (packageSelectVal === "3.2") {
        setPrice = 100;
      }
      if (packageSelectVal === "3.3") {
        setPrice = 0;
      }
      if (packageSelectVal === "4") {
        setPrice = 50;
      }
      if (packageSelectVal === "6") {
        setPrice = 100;
      }
      if (packageSelectVal === "7") {
        setPrice = 200;
      }
      if (packageSelectVal === "8") {
        setPrice = 300;
      }
    }
  }
  return setPrice;
}

function checkShippingEligibility() {
  var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  if (SelectedItems) {
    const filteredItems = SelectedItems.filter(
      (item) =>
        Number(item.estimatedTotalWeight) > 4500 ||
        Number(item.widthInches) > 96 ||
        Number(item.heightInches) > 96 ||
        Number(item.lengthInches) > 96
    );
    return filteredItems.length > 0;
  } else {
    return false;
  }
}



export const {
  getTotals,
  clearCart,
  handleAddCart,
  setShippingAddress,
  setBillingAddress,
  setPaymentMethod,
  handleConfirmShipRate,
  setTotalShippingAmount,
  handleUpdateQtyCart,
  handlePostalPrice,
  handleFreightPrice,
  setTotalTax,
} = cartSlice.actions;

export default cartSlice.reducer;
