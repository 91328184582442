export default function setWeightCriteriaForTLT(item) {
  // console.log("item", item);

  let qty = 0;
  if (item.selectedQty && Number(item.selectedQty) > 0) {
    qty = Number(item.selectedQty);
  }
  let weight = Number(item.estimatedTotalWeight) * qty;
  let width = Number(item.widthInches) * qty;
  let height = Number(item.heightInches) * qty;
  let length = Number(item.lengthInches) * qty;

  if (item.isLocalPickupOnly == true) {
    return null;
  }
  if (weight < 150 && width <= 96 && height <= 96 && length <= 96) {
    return false;
  }

  if (weight > 150 && width <= 96 && height <= 96 && length <= 96) {
    return true;
  }
  return null;
}
