
import "../app/globals.css";
import StoreProvider from "../globalRedux/StoreProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "@/context/AuthContext";
import { useEffect } from "react";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  // Scroll to top when route changes
  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo({ top: 0, behavior: "auto" });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  const getLayout = Component.getLayout || ((page) => page);
  return getLayout(
    <AuthProvider>
      <StoreProvider>
        <Component {...pageProps} />
        <ToastContainer style={{ fontSize: "14px", zIndex: 1000000 }} />
      </StoreProvider>
    </AuthProvider>
  );
}

export default MyApp;
