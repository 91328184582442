// context/AuthContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { getUser, login, logout } from '../utils/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = getUser();
    if (storedUser) {
      setUser(storedUser.user);
    }
  }, []);

  const handleLogin = async (payload) => {
    const user = await login(payload);
    if (user) {
      setUser(user);
    }
    return user;
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
